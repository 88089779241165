import React from 'react'

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import ComprobanteIngresos from '../components/pages/ComprobanteIngresos';
import GlobalFooterProvider from '../context/GlobalStateFooterContext';

class ComprobanteIngresosPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { pageContext } = this.props
        return (
            <GlobalFooterProvider>
            <LayoutsComponent
                broker={pageContext.broker}
                layout={pageContext.layout}
                companyName={pageContext.company}
                location={this.props.location}
                pageContext={this.props.pageContext}
                render={() => (
                    <>
                        <SEO title={pageContext.title} />
                        <ComprobanteIngresos pageContext={pageContext} />
                    </>
                )}
            />
         </GlobalFooterProvider>
        )
    }
}

export default ComprobanteIngresosPage;
